export enum TranslationKey{
  OfferIsAccepted = "offerIsAccepted",
  OfferIsRejected = "offerIsRejected",
  AcceptOffer = "acceptOffer",
  RejectOffer = "rejectOffer",
  DownloadPDF = "downloadPdf",
  OfferAcceptOrRejectConfirmed= "offerAcceptOrRejectConfirmed",
  OfferAcceptOrRejectError= "offerAcceptOrRejectError",
  WishToAcceptThisOffer = "wishToAcceptThisOffer",
  WishToRejectThisOffer = "wishToRejectThisOffer",
  TooltipZoomIn = "toolTipZoomIn",
  TooltipZoomOut = "toolTipZoomOut",
  TooltipPreviousPage = "toolTipPreviousPage",
  TooltipNextPage = "toolTipNextPage",
  TooltipOpenSearch = "toolTipSearchInDocument",
  TooltipCloseSearch = "toolTipCloseSearch",
  TooltipPrint = "toolTipPrint",
  TooltipDownload = "toolTipDownload",
  TooltipFullScreen = "toolTipFullscreen",
  Comments = "comments",
  CommentSectionPlaceholder = "commentSectionPlaceholder",
  SendComment = "sendComment",
  ClosePopup = "closePopup",
  Cancel = "cancel",
  Accept = "accept",
  Reject = "reject",
  AcceptOfferMessage = "acceptOfferMessage",
  RejectOfferMessage = "rejectOfferMessage",
  OfferExpiredHeadline = "offerExpiredHeadline",
  PleaseContact = "pleaseContact",
  OnPhone = "onPhone",
  OrOn = "orOn",
  ForFurtherInformation = "forFurtherInformation"
}