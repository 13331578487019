import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Offer from '../views/Offer.vue'
import NotFound from '../views/NotFound.vue'
import OfferExpired from '../views/OfferExpired.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/Home',
    // component: NotFound
  },
  {
    path: '/Home',
    name: 'Home',
    alias: '/NotFound',
    component: NotFound
  },
  {
    path: '/:guid',
    name: 'Offer',
    component: Offer
  },
  {
    path: '/OfferExpired',
    name: 'OfferExpired',
    component: OfferExpired
  },

  {
    path: '/NotFound',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
