import { StatesInOffersSource } from "@/DTOs/Enums/StatesInOffersSource"
import { LanguageType } from "@/i18n/languageTypes"
import { IOfferState } from "@/store/Interfaces/Offer/IOfferState"

export const state: IOfferState = {
  CurrentOffer: {
    ID: 0,
    MostRecentAcceptedOrDeclinedStateId: 0,
    PublicIdentifier: "",
    StateId: 0,
    OfferNumber: undefined,
    ValidUntilDate: ""
  },

  OfferDepartment: {
    Address: "",
    CVRNumber: "",
    City: "",
    DepartmentName: "",
    Email: "",
    LogoAsBase64: "",
    Phone: "",
    Zip: ""
  },

  PDF: new Uint8Array,
  PDFName: '',
  PdfScale: 1,
  CurrentPdfPageNumber: 0,
  TotalNumberOfPdfPages: 0,
  PdfIsFullScreen: false,
  PdfPageWasChangedManually: false,
  SiteInitializationComplete: false,
  OfferAccepted: false,
  OfferRejected: false,
  Language: LanguageType.DK,
  SiteOpenedWith: StatesInOffersSource.Portal
}