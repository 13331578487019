import { OfferCommentDTO } from "@/DTOs/OfferCommentDTO"
import { StateTypeEnumDTO } from "@/DTOs/StateTypeEnumDTO"
import { PdfHelper } from "@/Helpers/PdfHelper"
import { CommentMapper } from "@/Mappers/CommentMapper"
import { DepartmentMapper } from "@/Mappers/DepmartmentMapper"
import { OfferMapper } from "@/Mappers/OfferMapper"
import { ReportMapper } from "@/Mappers/ReportMapper"
import router from "@/router"
import { AugmentedActionContext } from "@/store/Modules/Offer/types"
import { IOfferActions, OfferActionTypes } from "@/store/Interfaces/Offer/IOfferAction"
import { OfferMutationTypes } from "@/store/Interfaces/Offer/IOfferMutation"
import axios from "axios"
import { ActionTree } from "vuex"
import { IOfferState } from "@/store/Interfaces/Offer/IOfferState"
import { RootState } from "@/store"
import { state } from "./state"
import DOMPurify from "dompurify"
import { OfferDTO } from "@/DTOs/OfferDTO"
import { OfferWithCommentsModel } from "@/models/OfferWithCommentsModel"
import { StateMapper } from "@/Mappers/StateMapper"
import { OfferInformationModel } from "@/models/OfferInformationModel"
import { StatesInOffersSource } from "@/DTOs/Enums/StatesInOffersSource"
import { EnumHelper } from "@/Helpers/EnumHelper"

export const actions: ActionTree<IOfferState, RootState> & IOfferActions = {

  [OfferActionTypes.InitializeSite]({ dispatch, commit }) {
    return new Promise(() => {
      dispatch(OfferActionTypes.GetOfferInformation, undefined)
        .then((offerInformationModel) => {
          commit(OfferMutationTypes.SetCurrentSiteName, offerInformationModel.SiteName)
          commit(OfferMutationTypes.SetStatesInOffersSource, offerInformationModel.OpenedWith)
          dispatch(OfferActionTypes.InitiliazeOfferAndCommentsWithPublicIdentifier, offerInformationModel.PublicIdentifier)
            .then(() => {
              dispatch(OfferActionTypes.ValidateOfferExpirationDate, undefined)
              .catch(() => {
                router.push("OfferExpired")
              })
              dispatch(OfferActionTypes.InitializePdfByOfferID, state.CurrentOffer.ID)
                .then(() => {
                  dispatch(OfferActionTypes.InitiliazeDepartment, undefined)
                    .then(() => {
                      dispatch(OfferActionTypes.InitializeOfferAcceptedOrRejected, state.CurrentOffer.MostRecentAcceptedOrDeclinedStateId)
                        .then(() => {
                          dispatch(OfferActionTypes.UpdateOfferStatus, StateTypeEnumDTO.seen)
                          commit(OfferMutationTypes.SetSiteInitializationStatus, true)
                        })
                        .catch(() => {
                          router.push("NotFound")
                        })
                    })
                    .catch(() => {
                      router.push("NotFound")
                    })
                })
                .catch(() => {
                  router.push("NotFound")
                })
            })
            .catch(() => {
              router.push("NotFound")
            })
        })
        .catch(() => {
          router.push("NotFound")
        })
      })
  },

  [OfferActionTypes.GetOfferInformation]() {
    return new Promise((resolve) => {
      const encodedUrl = window.location.href
      const urlArray = encodedUrl.split("/")
      const decodedUrlInformation = atob(urlArray[4]).split("/")
      const sourceID = parseInt(decodedUrlInformation[2] ?? StatesInOffersSource.Portal);

      const offerInformationModel: OfferInformationModel = {
        SiteName: decodedUrlInformation[0],
        PublicIdentifier: decodedUrlInformation[1],
        OpenedWith: EnumHelper.GetStatesInOfferEnumFromNumber(sourceID)
      }
      return resolve(offerInformationModel)
    })
  },

  [OfferActionTypes.GetOfferWithCommentsByPublicIdentifier]({ commit }: AugmentedActionContext, payload: string) {
    return new Promise((resolve, reject) => {
      axios.get("Offers/GetOffersWithCommentsById?publicIdentifiers=" + payload)
        .then((offerWithCommentsDTO) => {
          const commentDTOArray = offerWithCommentsDTO.data.data[0].comments as OfferCommentDTO[]
          const offerDTO = offerWithCommentsDTO.data.data[0].offer as OfferDTO

          const commentModelArray = CommentMapper.ToModelsFromDTOs(commentDTOArray)
          const offerModel = OfferMapper.ToModelFromDTO(offerDTO)

          const offerWithCommentsModel: OfferWithCommentsModel = {
            Offer: offerModel,
            Comments: commentModelArray
          }
          return resolve(offerWithCommentsModel)
        })
        .catch(() => {
          commit(OfferMutationTypes.SetCurrentOffer, undefined)
          commit(OfferMutationTypes.SetCurrentComments, undefined)
          return reject("Error recieving offer and comments.")
        })
    })
  },

  [OfferActionTypes.InitiliazeOfferAndCommentsWithPublicIdentifier]({ dispatch, commit }: AugmentedActionContext, payload: string) {
    return new Promise((resolve, reject) => {
      dispatch(OfferActionTypes.GetOfferWithCommentsByPublicIdentifier, payload)
        .then((offerWithCommentsModel) => {
          commit(OfferMutationTypes.SetCurrentOffer, offerWithCommentsModel.Offer);
          commit(OfferMutationTypes.SetCurrentComments, offerWithCommentsModel.Comments);
          commit(OfferMutationTypes.SortCommentsByDateCreated, undefined)
          commit(OfferMutationTypes.SetSiteTitleToOfferNumber, undefined)
          resolve();
        })
        .catch(() => {
          return reject()
        })
    })
  },

  [OfferActionTypes.GetOfferReportByOfferID]({ commit }: AugmentedActionContext, payload: number) {
    return new Promise((resolve, reject) => {
      axios.get("Offers/GetPDFAsBase64ByOfferID?offerID=" + payload)
        .then((offerReportDTO) => {
          if (offerReportDTO.data.HasErrors) {
            return reject("Errors in OfferReportDTO:\n" + offerReportDTO.data.ValidationErrors)
          }
          const reportModel = ReportMapper.ToModelFromDTO(offerReportDTO.data.data)
          return resolve(reportModel)
        })
        .catch(() => {
          commit(OfferMutationTypes.SetCurrentPdf, undefined)
          return reject("Error recieving offer report.")
        })
    })
  },

  [OfferActionTypes.InitializePdfByOfferID]({ dispatch, commit }: AugmentedActionContext, payload: number) {
    return new Promise((resolve, reject) => {
      dispatch(OfferActionTypes.GetOfferReportByOfferID, payload)
        .then((offerReportModel) => {
          const pdfByteArray = PdfHelper.FromBase64ToUIntArray(offerReportModel.FileData)
          commit(OfferMutationTypes.SetCurrentPdf, pdfByteArray);
          commit(OfferMutationTypes.SetPDFName, offerReportModel.FileName);
          resolve();
        })
        .catch(() => {
          return reject()
        })
    })
  },

  [OfferActionTypes.GetDepartmentInformation]({ commit }: AugmentedActionContext) {
    return new Promise((resolve, reject) => {
      axios.get("api/v3.0/DepartmentInformation")
        .then(departmentInformationDTO => {
          if (departmentInformationDTO.data.HasErrors) {
            return reject("Errors in DepartmentInformationDTO:\n" + departmentInformationDTO.data.ValidationErrors)
          }
          const departmentModel = DepartmentMapper.ToModelFromDTO(departmentInformationDTO.data.data)
          return resolve(departmentModel)
        })
        .catch(() => {
          commit(OfferMutationTypes.SetCurrentDepartment, undefined)
          return reject("Error updating department information")
        })
    })
  },

  [OfferActionTypes.InitiliazeDepartment]({ dispatch, commit }: AugmentedActionContext) {
    return new Promise((resolve, reject) => {
      dispatch(OfferActionTypes.GetDepartmentInformation, undefined)
        .then((departmentModel) => {
          commit(OfferMutationTypes.SetCurrentDepartment, departmentModel);
          resolve();
        })
        .catch(() => {
          return reject()
        })
    })
  },

  [OfferActionTypes.InitializeOfferAcceptedOrRejected]({ commit }: AugmentedActionContext, payload: StateTypeEnumDTO) {
    return new Promise((resolve) => {
      commit(OfferMutationTypes.SetOfferAcceptedOrRejected, payload);
      resolve();
    })
  },

  [OfferActionTypes.UpdateOfferStatus]({ commit }: AugmentedActionContext, payload: StateTypeEnumDTO) {
    return new Promise((resolve, reject) => {
      const updateStatusDTO = StateMapper.ToUpdateStatusDTOFromStateTypeEnum(payload, state.CurrentOffer.PublicIdentifier, state.SiteOpenedWith)

      axios.put("Offers/UpdateState", updateStatusDTO)
        .then(responseDTO => {
          if (responseDTO.data.HasErrors) {
            return reject("Errors in DepartmentInformationDTO:\n" + responseDTO.data.ValidationErrors)
          }
          commit(OfferMutationTypes.SetOfferAcceptedOrRejected, payload)
          commit(OfferMutationTypes.SetCurrentOfferStatus, payload)
          return resolve()
        })
        .catch(() => {
          return reject()
        })
    })
  },

  [OfferActionTypes.InitializeNewComment]({ commit, dispatch }: AugmentedActionContext, payload: string) {
    return new Promise((resolve, reject) => {
      const commentText = DOMPurify.sanitize(payload).trim();

      dispatch(OfferActionTypes.SendCommentToAPI, commentText)
        .then(commentModel => {
          commit(OfferMutationTypes.InsertNewComment, commentModel)

          return resolve()
        })
        .catch(() => {
          reject()
        })
    })
  },

  [OfferActionTypes.SendCommentToAPI]({ state }: AugmentedActionContext, payload: string) {
    return new Promise((resolve, reject) => {
      const commentDTO = CommentMapper.ToDTOFromStringAndOfferId(payload, state.CurrentOffer.ID)
      axios.post("Offers/PostComments", [commentDTO])
        .then(offerCommentDTOArray => {
          if (offerCommentDTOArray.data.HasErrors) {
            return reject('Errors in OfferCommentDTOArray:\n' + offerCommentDTOArray.data.ValidationErrors)
          }

          const newCommentAsDTO = offerCommentDTOArray.data.data[0] as OfferCommentDTO
          const newCommentAsModel = CommentMapper.ToModelFromDTO(newCommentAsDTO)
          return resolve(newCommentAsModel)
        })
        .catch(() => {
          reject()
        })
    })
  },

  [OfferActionTypes.InitializePdfDownload]({ state }: AugmentedActionContext) {
    return new Promise((resolve) => {
      PdfHelper.DownloadPdf(state.PDF, state.PDFName)
      resolve()
    })
  },

  [OfferActionTypes.ValidateOfferExpirationDate]() {
    return new Promise((resolve, reject) => {
      const expirationDate = new Date(state.CurrentOffer.ValidUntilDate.split('T')[0]).setHours(0, 0, 0, 0)
      const todayDate = new Date().setHours(0, 0, 0, 0);

      if((expirationDate < todayDate) && 
      !state.OfferAccepted) {
        reject()
      }

      resolve()
    })
  }
}