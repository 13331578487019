import { createStore } from 'vuex'
import { offer} from './Modules/Offer'
import { OfferStore } from '@/store/Modules/Offer/types'
import { IOfferState } from './Interfaces/Offer/IOfferState'

export type RootState = {
  offer: IOfferState 
}

export type Store = OfferStore<Pick<RootState, 'offer'>>

export const store = createStore({
  modules: {
    offer,
  },
})

export function useStore(): Store {
  return store as Store
}