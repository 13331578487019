
import { computed, defineComponent } from "vue"
import PdfButton from "../Buttons/PdfButton.vue"
import PdfPaginationButton from "../Buttons/DualPdfButton.vue"
import { OfferMutationTypes } from "@/store/Interfaces/Offer/IOfferMutation"
import { OfferActionTypes } from "@/store/Interfaces/Offer/IOfferAction"
import { useStore, store } from "@/store"
import { PDFPageWrapperModel } from "@/models/PDFPageWrapperModel"
import { TranslationKey } from "@/i18n/translationKeys"

export default defineComponent({
  name: "pdf-toolbar",
  components: { PdfPaginationButton, PdfButton },
  data() {
    return {
      isSearchActive: false,
      searchString: "",
      OfferActionTypes,
      toolTipZoomIn: this.$t(TranslationKey.TooltipZoomIn),
      toolTipZoomOut: this.$t(TranslationKey.TooltipZoomOut),
      toolTipPreviousPage: this.$t(TranslationKey.TooltipPreviousPage),
      toolTipNextPage: this.$t(TranslationKey.TooltipNextPage),
      toolTipOpenSearch: this.$t(TranslationKey.TooltipOpenSearch),
      toolTipCloseSearch: this.$t(TranslationKey.TooltipCloseSearch),
      toolTipPrint: this.$t(TranslationKey.TooltipPrint),
      toolTipDownload: this.$t(TranslationKey.TooltipDownload),
      toolTipFullscreen: this.$t(TranslationKey.TooltipFullScreen)
    }
  },

  setup() {
    const store = useStore();

    const totalNumberOfPdfPages = computed(
      () => store.getters.GetTotalNumberOfPdfPages
    )

    const currentPdfPage = computed(
      () => store.getters.GetCurrentPdfPage
    )
    
    const currentPdfScale = computed(
      () => store.getters.GetPdfScale
    )

    const isPdfFullscreen = computed(
      () => store.getters.GetPdfFullscreenValue
    )

    return {
      totalNumberOfPdfPages,
      currentPdfPage,
      currentPdfScale,
      isPdfFullscreen
    }
  },

  watch: {
    Language() {
      this.toolTipZoomIn = this.$t(TranslationKey.TooltipZoomIn),
      this.toolTipZoomOut = this.$t(TranslationKey.TooltipZoomOut),
      this.toolTipPreviousPage = this.$t(TranslationKey.TooltipPreviousPage),
      this.toolTipNextPage = this.$t(TranslationKey.TooltipNextPage),
      this.toolTipOpenSearch = this.$t(TranslationKey.TooltipOpenSearch),
      this.toolTipCloseSearch = this.$t(TranslationKey.TooltipCloseSearch),
      this.toolTipPrint = this.$t(TranslationKey.TooltipPrint),
      this.toolTipDownload = this.$t(TranslationKey.TooltipDownload),
      this.toolTipFullscreen = this.$t(TranslationKey.TooltipFullScreen)
    }
  },

  methods: {
    zoomOut() {
      const scaleX10 = this.$store.getters.GetPdfScale * 10
      const newScaleX10 = scaleX10 % 1 == 0 ? scaleX10 - 1 : Math.floor(scaleX10)
      this.$store.commit(OfferMutationTypes.SetPdfScale, newScaleX10 / 10)
    },
    zoomIn() {
      const scaleX10 = this.$store.getters.GetPdfScale * 10
      const newScaleX10 = scaleX10 % 1 == 0 ? scaleX10 + 1 : Math.ceil(scaleX10)
      this.$store.commit(OfferMutationTypes.SetPdfScale, newScaleX10 / 10)
    },

    updatePage(pageValue: string) {
      let pageValueNum = 0
      if(typeof pageValue === "string") {
        pageValueNum = parseInt(pageValue)
      } else {
        pageValueNum = pageValue
      }

      if( pageValueNum < 1) {
        pageValueNum = 1
      }
      const pdfPageWrapper: PDFPageWrapperModel = {
        newPageNumber: pageValueNum,
        isChangedManually: true
      }

      this.$store.commit(OfferMutationTypes.SetCurrentPdfPage, pdfPageWrapper)
      const ref = this.$refs.paginator as any // eslint-disable-line
      ref.$forceUpdate()
    },

    updateZoom(zoomValue: number) {
      if(zoomValue > 500) {
        zoomValue = 500
      } 
      else if(zoomValue < 20){
        zoomValue = 20
      }

      this.$store.commit(OfferMutationTypes.SetPdfScale, zoomValue / 100)
      const ref = this.$refs.zoominator as any // eslint-disable-line
      ref.$forceUpdate()
    },

    toggleSearch() {
      this.$emit('search')
      this.isSearchActive = !this.isSearchActive

      this.$nextTick(() => {
        const el = this.$refs.search as HTMLInputElement
        if(el) {
          el.focus()
        }
      })
    },

    downloadPdf() {
      const store = useStore();
      store.dispatch(OfferActionTypes.InitializePdfDownload, undefined)
    }
    
  },
  computed: {
    Language() {
      return store.getters.GetCurrentLanguage
    }
  },
})
