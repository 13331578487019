import { OfferDTO } from '@/DTOs/OfferDTO'
import { OfferModel } from '@/models/OfferModel'

export class OfferMapper {
  public static ToModelFromDTO(dto: OfferDTO): OfferModel {
    return {
      ID: dto.id,
      PublicIdentifier: dto.publicIdentifier,
      StateId: dto.stateId,
      MostRecentAcceptedOrDeclinedStateId: dto.mostRecentAcceptedOrDeclinedStateId,
      OfferNumber: dto.offerNumber,
      ValidUntilDate: dto.validUntilDate
    }
  }
}