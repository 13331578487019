import { DepartmentInformationDTO } from '@/DTOs/DepartmentInformationDTO'
import { DepartmentModel } from '@/models/DepartmentModel'

export class DepartmentMapper {
  public static ToModelFromDTO(dto: DepartmentInformationDTO): DepartmentModel {
    return {
      Address: dto.address,
      City: dto.city,
      CVRNumber: dto.cvrNumber,
      Email: dto.email,
      LogoAsBase64: dto.logo,
      DepartmentName: dto.name,
      Phone: dto.phone,
      Zip: dto.zip
    }
  }
}