
import { defineComponent } from "vue"
import OfferNavigationBar from "@/components/OfferNavigationBar.vue"
import Banner from "@/components/Banner.vue"
import FooterSection from "@/components/FooterSection.vue"


export default defineComponent({
  components: { OfferNavigationBar, Banner, FooterSection},
  name: "App"
})
