
import { defineComponent } from 'vue'

export default defineComponent({
  name: "offer-button",
  props: {
    icon: String,
    text: String,
    color: {
      type: String,
      default: "var(--primary-color)"
    },
    enabled: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    onClicked(event: Event) {
      if(this.enabled) this.$emit('click', event)
    }
  },
  computed: {
    style(): string {
      if(this.enabled) return '--hovercolor:' + this.color
      else return 'pointer-events:none' //Disable all mouse events
    },
  },
  emits: ['click'],
})
