import { StatesInOffersSource } from "@/DTOs/Enums/StatesInOffersSource";

export class EnumHelper {
    public static GetStatesInOfferEnumFromNumber(sourceID: number): StatesInOffersSource {
        switch(sourceID) {
            case StatesInOffersSource.Web: return StatesInOffersSource.Web;
            case StatesInOffersSource.Portal: return StatesInOffersSource.Portal;
            default: return StatesInOffersSource.Portal;
          }
    }
}