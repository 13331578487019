
import { CommentModel } from "@/models/CommentModel"
import { defineComponent } from "vue"

export default defineComponent({
  name: "comment",
  props: {
    comment: Object as () => CommentModel,
  },
  computed: {
    icon(): string {
      if(this.comment?.CreatedBy == null){
        return "textsms"
      } else {
        return "construction"
      }
    }
  }
})
