import { StateTypeEnumDTO } from "@/DTOs/StateTypeEnumDTO"
import { OfferWithCommentsModel } from "@/models/OfferWithCommentsModel"
import { AugmentedActionContext } from "@/store/Modules/Offer/types"
import { ReportModel } from "@/models/ReportModel"
import { DepartmentModel } from "@/models/DepartmentModel"
import { CommentModel } from "@/models/CommentModel"
import { OfferInformationModel } from "@/models/OfferInformationModel"

export enum OfferActionTypes {
  InitializeSite = 'InitializeSite',
  GetOfferInformation = 'GetOfferInformation',
  GetOfferWithCommentsByPublicIdentifier = 'GetOfferWithCommentsByPublicIdentifier',
  InitiliazeOfferAndCommentsWithPublicIdentifier = 'InitiliazeOfferAndCommentsWithPublicIdentifier',
  GetOfferReportByOfferID = 'GetOfferReportByOfferID',
  InitializePdfByOfferID = 'InitializePdfByOfferID',
  GetDepartmentInformation = 'GetDepartmentInformation',
  InitiliazeDepartment = 'InitiliazeDepartment',
  InitializeOfferAcceptedOrRejected = 'InitializeOfferAcceptedOrRejected',
  UpdateOfferStatus = 'UpdateOfferStatus',
  InitializeNewComment = 'InitializeNewComment',
  SendCommentToAPI = 'SendCommentToAPI',
  InitializePdfDownload = 'InitializePdfDownload',
  ValidateOfferExpirationDate = 'ValidateOfferExpirationDate',
}

export interface IOfferActions {
  [OfferActionTypes.InitializeSite]({ dispatch }: AugmentedActionContext): Promise<void>
  [OfferActionTypes.GetOfferInformation](): Promise<OfferInformationModel>
  [OfferActionTypes.GetOfferWithCommentsByPublicIdentifier]({ commit }: AugmentedActionContext, payload: string): Promise<OfferWithCommentsModel>
  [OfferActionTypes.InitiliazeOfferAndCommentsWithPublicIdentifier]({ dispatch, commit }: AugmentedActionContext, payload: string): Promise<void>
  [OfferActionTypes.GetOfferReportByOfferID]({ commit }: AugmentedActionContext, payload: number): Promise<ReportModel>
  [OfferActionTypes.InitializePdfByOfferID]({ dispatch, commit }: AugmentedActionContext, payload: number): Promise<void>
  [OfferActionTypes.GetDepartmentInformation]({ commit }: AugmentedActionContext): Promise<DepartmentModel>
  [OfferActionTypes.InitiliazeDepartment]({ dispatch, commit }: AugmentedActionContext): Promise<void>
  [OfferActionTypes.InitializeOfferAcceptedOrRejected]({ commit }: AugmentedActionContext, payload: StateTypeEnumDTO): Promise<void>
  [OfferActionTypes.UpdateOfferStatus]({ commit }: AugmentedActionContext, payload: StateTypeEnumDTO): Promise<void>
  [OfferActionTypes.InitializeNewComment]({ dispatch, commit }: AugmentedActionContext, payload: string): Promise<void>
  [OfferActionTypes.SendCommentToAPI]({commit}: AugmentedActionContext, payload: string): Promise<CommentModel>
  [OfferActionTypes.InitializePdfDownload]({ dispatch, commit }: AugmentedActionContext, payload: undefined): Promise<void>
  [OfferActionTypes.ValidateOfferExpirationDate](): Promise<void>
}