import { OfferCommentDTO } from '@/DTOs/OfferCommentDTO'
import { CommentModel } from '@/models/CommentModel'

export class CommentMapper {
  public static ToModelFromDTO(dto: OfferCommentDTO): CommentModel {
    return {
      Id: dto.id,
      Text: dto.text,
      DateCreated: new Date(dto.dateCreated),
      CreatedBy: dto.createdBy
    }
  }
  public static ToModelsFromDTOs(commentDTOArray: OfferCommentDTO[]): CommentModel[] {
    const commentModelArray: CommentModel[] =  commentDTOArray.map(comment => this.ToModelFromDTO(comment))
    return commentModelArray
  }

  public static ToDTOFromStringAndOfferId(commentText: string, offerId: number): OfferCommentDTO {
    return {
      text: commentText,
      dateCreated: new Date(),
      offerId: offerId
    }
  }
}