
import { computed, defineComponent } from "vue"
import ConfirmationPopup from "@/components/ConfirmationPopup.vue"
import OfferButton from "@/components/Buttons/OfferButton.vue"
import { OfferActionTypes } from "@/store/Interfaces/Offer/IOfferAction"
import { useStore, store } from "@/store"
import { TranslationKey } from "@/i18n/translationKeys"
import { StateTypeEnumDTO } from "@/DTOs/StateTypeEnumDTO"

export default defineComponent({
  name: "buttons-for-offer-view",
  components: {
    OfferButton,
    ConfirmationPopup,
  },

  data() {
    return {
      showPopup: false,
      popupMsg: "",
      confirmBtnText: "",
      btnColor: "",
      isAcceptPopup: false,
      StateTypeEnumDTO,
      OfferActionTypes,
      store: useStore(),
      offerIsAccepted: this.$t(TranslationKey.OfferIsAccepted),
      offerIsRejected: this.$t(TranslationKey.OfferIsRejected),
      rejectOffer: this.$t(TranslationKey.RejectOffer),
      acceptOffer: this.$t(TranslationKey.AcceptOffer),
      downloadPDF: this.$t(TranslationKey.DownloadPDF),
      accept: this.$t(TranslationKey.Accept),
      reject: this.$t(TranslationKey.Reject),
      acceptOfferMessage: this.$t(TranslationKey.AcceptOfferMessage),
      rejectOfferMessage: this.$t(TranslationKey.RejectOfferMessage)
    }
  },
    computed: {
    Language() {
      return store.getters.GetCurrentLanguage
      }
    },

  watch: {
    Language() {
      this.offerIsAccepted = this.$t(TranslationKey.OfferIsAccepted),
      this.offerIsRejected = this.$t(TranslationKey.OfferIsRejected),
      this.rejectOffer = this.$t(TranslationKey.RejectOffer),
      this.acceptOffer = this.$t(TranslationKey.AcceptOffer),
      this.downloadPDF = this.$t(TranslationKey.DownloadPDF),
      this.accept = this.$t(TranslationKey.Accept),
      this.reject = this.$t(TranslationKey.Reject),
      this.acceptOfferMessage = this.$t(TranslationKey.AcceptOfferMessage),
      this.rejectOfferMessage = this.$t(TranslationKey.RejectOfferMessage)
    }
  },

  setup(){
    const store = useStore()

    const offerAccepted = computed(
      () => store.getters.GetOfferAccepted
    )

    const offerRejected = computed(
      () => store.getters.GetOfferRejected
    )

    const siteIsInitialized = computed(
      () => store.getters.GetSiteInitializationStatus
    )

    return {
      offerAccepted,
      offerRejected,
      siteIsInitialized
    }
  },

  methods: {
    rejectClicked(message: string, btnText: string) {
      this.popupMsg = message
      this.confirmBtnText = btnText
      this.btnColor = "red"
      this.isAcceptPopup = false
      this.showPopup = true
    },
    acceptClicked(message: string, btnText: string) {
      this.popupMsg = message
      this.confirmBtnText = btnText
      this.btnColor = "green"
      this.isAcceptPopup = true
      this.showPopup = true
    },
    
    downloadPdf() {
      this.store.dispatch(OfferActionTypes.InitializePdfDownload, undefined)
    },
  }
})
