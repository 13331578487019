<template>
  <div class="single-tool tooltip">
      <span class="material-icons icon" @click="singleMethod"> {{ singleIcon }} </span>
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "pdf-button",

  props: {
    singleIcon: {
      type: String,
      default: "",
    },
  },

  methods: {
    singleMethod() {
      this.$emit("singleMethod")
    },
  },
  emits: ['singleMethod'],
})
</script>

<style scoped>
.single-tool {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    background-color: #bcbcbc;
    height: 25px;
    width: 25px;
    user-select: none;
}

.icon {
    font-size: 1.3em;
}

.icon:hover {
    color: #555;
    cursor: pointer;
}
</style>
