import { LanguageType } from "@/i18n/languageTypes"
import { RootState } from "@/store"
import { IOfferGetters, OfferGetterTypes } from "@/store/Interfaces/Offer/IOfferGetters"
import { IOfferState } from "@/store/Interfaces/Offer/IOfferState"
import { GetterTree } from "vuex"

export const getters: GetterTree<IOfferState, RootState> & IOfferGetters = {
  [OfferGetterTypes.GetOfferDepartment]: (state: IOfferState) => {
    return state.OfferDepartment
  },
  [OfferGetterTypes.GetPdfScale]: (state: IOfferState) => {
    return state.PdfScale
  },
  [OfferGetterTypes.GetCurrentPdfPage]: (state: IOfferState) => {
    return state.CurrentPdfPageNumber
  },
  [OfferGetterTypes.GetPdfFullscreenValue]: (state: IOfferState) => {
    return state.PdfIsFullScreen
  },
  [OfferGetterTypes.GetPdfManuallyChangedValue]: (state: IOfferState) => {
    return state.PdfPageWasChangedManually
  },
  [OfferGetterTypes.GetSiteInitializationStatus]: (state: IOfferState) => {
    return state.SiteInitializationComplete
  },
  [OfferGetterTypes.GetTotalNumberOfPdfPages]: (state: IOfferState) => {
    return state.TotalNumberOfPdfPages
  },
  [OfferGetterTypes.GetOfferAccepted]: (state: IOfferState) => {
    return state.OfferAccepted
  },
  [OfferGetterTypes.GetOfferRejected]: (state: IOfferState) => {
    return state.OfferRejected
  },
  [OfferGetterTypes.GetTotalNumberOfComments]: (state: IOfferState) => {
    return state.CurrentComments?.length
  },
  [OfferGetterTypes.GetAllComments]: (state: IOfferState) => {
    return state.CurrentComments
  },
  [OfferGetterTypes.GetPdfDocument]: (state: IOfferState) => {
    return state.PDF
  },
  [OfferGetterTypes.GetCurrentLanguage]: (state: IOfferState) => {
    return state.Language as LanguageType
  },
}