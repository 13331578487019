
import { defineComponent } from "vue";
import PdfToolbar from "@/components/PdfViewer/PdfToolbar.vue";
import { OfferMutationTypes } from "@/store/Interfaces/Offer/IOfferMutation";
import * as pdfjs from "pdfjs-dist/webpack";
import { PDFPageProxy } from "pdfjs-dist/webpack";
import * as pdfjsViewer from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";

export default defineComponent({
  name: "pdf-viewer",

  components: {
    PdfToolbar,
  },
  data(): data {
    return {
      pages: undefined,
      pdfViewer: undefined,
      pdfFindController: undefined,
      pdfLinkService: undefined,
      OfferMutationTypes,
    };
  },
  computed: {
    pdfDocument() {
      return this.$store.getters.GetPdfDocument
    },
    pdfScale() {
      return this.$store.getters.GetPdfScale
    },
  },

  watch: {
    pdfDocument() {
      pdfjs.getDocument({ data: this.$store.getters.GetPdfDocument })
        .promise
        .then(pdf => {
          this.pdfViewer.setDocument(pdf);
          this.pdfLinkService.setDocument(pdf, null);
          this.$store.commit(OfferMutationTypes.SetNumberOfPdfPages, pdf.numPages);

          const pagePromises = Array<Promise<PDFPageProxy>>(pdf.numPages);
          for (let i = 0; i < pdf.numPages; i++) {
            pagePromises[i] = pdf.getPage(i + 1);
          }
          return Promise.all(pagePromises);
        })
        .then((pages) => {
          this.pages = pages;
        });
    },

    CurrentPdfPage(newPage) {
      if (this.$store.state.PdfPageWasChangedManually) {
        if (this.pdfViewer) this.pdfViewer.CurrentPdfPageNumber = newPage;
      }
    },
    pdfScale(pdfScale) {
      this.pdfViewer.currentScale = pdfScale;
    },
  },

  methods: {
    search(searchString: string, reverse = false) {
      //The find again command lets you go through matches with the same query
      this.pdfFindController.executeCommand("findagain", {
        highlightAll: true,
        phraseSearch: true, //Don't just search for individual words
        findPrevious: reverse,
        query: searchString,
      });
    },
    expand() {
      /* eslint-disable @typescript-eslint/no-explicit-any */ //Disabeling any for older brower support
      if (
        document.fullscreenElement ||
        (document as any).webkitFullscreenElement ||
        (document as any).mozFullScreenElement
      ) {
        if (document.exitFullscreen) {
          document
            .exitFullscreen()
            .then(() =>
              this.$store.commit(OfferMutationTypes.SetPdfFullScreen, false)
            );
        } else if ((document as any).webkitExitFullscreen) {
          /* Safari */
          (document as any)
            .webkitExitFullscreen()
            .then(() =>
              this.$store.commit(OfferMutationTypes.SetPdfFullScreen, false)
            );
        } else if ((document as any).msExitFullscreen) {
          /* IE11 */
          (document as any)
            .msExitFullscreen()
            .then(() =>
              this.$store.commit(OfferMutationTypes.SetPdfFullScreen, false)
            );
        } /* eslint-enable */
      } else {
        if (this.$el.requestFullscreen) {
          (this.$el as HTMLDivElement)
            .requestFullscreen()
            .then(() =>
              this.$store.commit(OfferMutationTypes.SetPdfFullScreen, true)
            );
        } else if (this.$el.webkitRequestFullscreen) {
          /* Safari */
          this.$el
            .webkitRequestFullscreen()
            .then(() =>
              this.$store.commit(OfferMutationTypes.SetPdfFullScreen, true)
            );
        } else if (this.$el.msRequestFullscreen) {
          /* IE11 */
          this.$el
            .msRequestFullscreen()
            .then(() =>
              this.$store.commit(OfferMutationTypes.SetPdfFullScreen, true)
            );
        }
      }
    },
    print(dpi: number) {
      if (!this.pages) return;
      // 1in == 72pt
      // 1in == 96px
      const PRINT_RESOLUTION = dpi === undefined ? 150 : dpi;
      const PRINT_UNITS = PRINT_RESOLUTION / 72.0;
      const CSS_UNITS = 96.0 / 72.0;

      //HACK: reuse iframe as it breaks printing on phones when we try to remove it.
      let iframeElt = document.getElementById(
        "print-frame"
      ) as HTMLIFrameElement;
      if (iframeElt) {
        iframeElt.src = "about:blank";
      } else {
        iframeElt = document.createElement("iframe");
        iframeElt.id = "print-frame";
      }

      //Div to render canvases outside of iframe, while some fonts breaks inside it.
      const tempElt = document.createElement("div");
      tempElt.style.cssText = "display: none";
      window.document.body.appendChild(tempElt);

      const removePrintElements = () => {
        // if(iframeElt.parentNode)
        //   iframeElt.parentNode.removeChild(iframeElt)
        if (tempElt.parentNode) tempElt.parentNode.removeChild(tempElt);
      };

      new Promise<Window>((resolve, reject) => {
        // CSS to hide iframe without mamking it "display:none", as it will break printing from it
        iframeElt.style.cssText =
          "border: 0; overflow: hidden; width: 0; height: 0; position: fixed; top: 0; left: 0";

        iframeElt.onload = () => {
          const contentWindow = iframeElt.contentWindow;
          if (contentWindow) {
            contentWindow.document.title = "";

            if (this.pages) {
              //Style iFrame for printing...(TODO: may need changes for eg A3 pages)
              var viewport = this.pages[0].getViewport({ scale: 1 });
              contentWindow.document.body.appendChild(
                contentWindow.document.createElement("style")
              ).textContent =
                "@supports ((size:A4) and (size:1pt 1pt)) {" +
                "@page { size: " +
                (viewport.width * PRINT_UNITS) / CSS_UNITS +
                "pt " +
                (viewport.height * PRINT_UNITS) / CSS_UNITS +
                "pt; }" +
                "}" +
                //Firefox doesn't support 'size' properly, but fits contet to page width by default - So not a prblem?
                "@page { margin: 0pt }" +
                "@media print {" +
                "body { margin: 0 }" +
                "canvas { page-break-before: avoid; page-break-after: always; page-break-inside: avoid }" +
                "}" +
                "@media screen {" +
                "body { margin: 0 }" +
                "}";
            }
            resolve(contentWindow);
          } else {
            reject();
          }
        };

        window.document.body.appendChild(iframeElt);
      }).then((contentWindow) => {
        const allPages = Array<Promise<void>>(
          this.$store.state.TotalNumberOfPdfPages
        );

        //Render each page into it's own canvas at 100% scale
        this.pages?.forEach((page) => {
          const viewport = page.getViewport({ scale: 1 });

          const printCanvasElt = tempElt.appendChild(
            document.createElement("canvas")
          );
          printCanvasElt.width = viewport.width * PRINT_UNITS;
          printCanvasElt.height = viewport.height * PRINT_UNITS;

          const canvasContext = printCanvasElt.getContext("2d");

          if (canvasContext) {
            allPages.push(
              page.render({
                canvasContext: canvasContext,
                transform: [
                  // Additional transform, applied just before viewport transform.
                  PRINT_UNITS,
                  0,
                  0,
                  PRINT_UNITS,
                  0,
                  0,
                ],
                viewport: viewport,
                intent: "print",
              }).promise
            );
          }
        });

        //Copy all canvases to iFrame and print it, once all pages are rendered
        Promise.all(allPages)
          .then(() => {
            /**
             * Hack: As appendChild will remove elements from perent/array immediately
             * We note length of it before the loop, and alway move element 0
             * As it will be the next canvas
             */
            const numCanvasToMove = tempElt.children.length;
            for (var i = 0; i < numCanvasToMove; ++i) {
              var canvas = tempElt.children[0];
              contentWindow.document.body.appendChild(canvas);
            }

            contentWindow.onafterprint = () => removePrintElements();

            contentWindow.focus(); // Required for IE
            if (contentWindow.document.queryCommandSupported("print")) {
              contentWindow.document.execCommand("print", false, undefined);
            } else {
              contentWindow.print();
            }
          })
          .catch(() => {
            removePrintElements();
          });
      });
    },
  },

  mounted() {
    //Initialize pdf_viewer.js
    const container = this.$refs.pdfPageContainer as HTMLDivElement;
    const eventBus = new pdfjsViewer.EventBus();

    //Enable hyperlinks within PDF files.
    this.pdfLinkService = new pdfjsViewer.PDFLinkService({ eventBus });

    //Enable find controller.
    this.pdfFindController = new pdfjsViewer.PDFFindController({
      eventBus,
      linkService: this.pdfLinkService,
    });

    //Init pdfjsViewer
    this.pdfViewer = new pdfjsViewer.PDFViewer({
      container,
      eventBus,
      //removePageBorders: true,
      textLayerMode: 2, // 0=off 1=default 2=enhanced selection
      linkService: this.pdfLinkService,
      findController: this.pdfFindController,
    });
    this.pdfLinkService.setViewer(this.pdfViewer);

    //Listen to pdfViewer events
    eventBus.on("pagechanging", (event: { pageNumber: number }) => {
      this.$store.commit(OfferMutationTypes.SetCurrentPdfPage, {
        newPageNumber: event.pageNumber,
        isChangedManually: false,
      });
    });
    eventBus.on("scalechanging", (event: { scale: number }) => {
      this.$store.commit(OfferMutationTypes.SetPdfScale, event.scale);
    });
    eventBus.on("pagesinit", () => {
      //Make pdf fill entire width on load
      this.pdfViewer.currentScaleValue = "page-width";
      this.$store.commit(OfferMutationTypes.SetCurrentPdfPage, {
        newPageNumber: this.pdfViewer.currentPageNumber,
        isChangedManually: false,
      });
    });

    /** PINCH ZOOM
     * While zooming elements are scaled with CSS scale and tranform so that
     * pdfViewer doesn't rerender pages unnecessarily, but only on touch end.
     */
    let startX = 0,
      startY = 0;
    let initialPinchDistance = 0;
    let pinchScale = 1;
    const viewer = this.$refs.viewer as HTMLDivElement;
    const reset = () => {
      startX = 0;
      startY = 0;
      initialPinchDistance = 0;
      pinchScale = 1;
    };

    viewer.addEventListener("touchstart", (e) => {
      if (e.touches.length > 1) {
        //Save initial touch state.
        startX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
        startY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
        initialPinchDistance = Math.hypot(
          e.touches[1].pageX - e.touches[0].pageX,
          e.touches[1].pageY - e.touches[0].pageY
        );
      } else {
        initialPinchDistance = 0;
      }
    });

    viewer.addEventListener("touchmove", (e: any) => {
      //eslint-disable-line
      if (initialPinchDistance <= 0 || e.touches.length < 2) {
        return;
      }
      if (e.scale !== 1) {
        e.preventDefault();
      }

      const pinchDistance = Math.hypot(
        e.touches[1].pageX - e.touches[0].pageX,
        e.touches[1].pageY - e.touches[0].pageY
      );
      const originX = startX + container.scrollLeft;
      const originY = startY + container.scrollTop;
      pinchScale = pinchDistance / initialPinchDistance;

      //CSS scale and transform while pinch zooming
      viewer.style.transform = `scale(${pinchScale})`;
      viewer.style.transformOrigin = `${originX}px ${originY}px`;
    });

    viewer.addEventListener("touchend", () => {
      if (initialPinchDistance <= 0) {
        return;
      }

      //Reset CSS styleing from zoom
      viewer.style.transform = `none`;
      viewer.style.transformOrigin = `unset`;

      //Store margin for calculations after pdfViewer scaled
      let marginBeforePDFScale = 0;
      const page = viewer.firstElementChild;
      if (page) {
        marginBeforePDFScale = parseFloat(
          window.getComputedStyle(page).marginLeft
        );
      }

      //Let pdfViewer calcualte actual scale
      this.pdfViewer.currentScale *= pinchScale;

      //Calculate and set scroll after pdfviewer scale
      const rect = container.getBoundingClientRect();
      const dx = startX - rect.left;
      const dxNoMargin = dx - marginBeforePDFScale;
      const dy = startY; // - rect.top
      container.scrollLeft +=
        dxNoMargin * (pinchScale - 1) - marginBeforePDFScale;
      container.scrollTop += dy * (pinchScale - 1);

      reset();
    });
  },
});

interface data {
  /* eslint-disable @typescript-eslint/no-explicit-any */ //Disableing any as pdf_viewer.js has no TS
  pages?: PDFPageProxy[];
  pdfViewer?: any;
  pdfFindController?: any;
  pdfLinkService?: any;
  OfferMutationTypes: typeof OfferMutationTypes /* eslint-enable */;
}
