
import { defineComponent } from "vue"

export default defineComponent({
  name: "pdf-pagination-button",

  props: {
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
    primaryContent: {
      type: undefined,
      default: undefined,
    },
    secondaryContent: {
      type: String,
      default: "",
    },
    leftTooltip: {
      type: String,
      default: "",
    },
    rightTooltip: {
      type: String,
      default: "",
    },
    minValue: {
      type: Number,
      default: undefined,
    },
    maxValue: {
      type: Number,
      default: undefined,
    },
    inputType: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    leftMethod() {
      this.$emit("leftMethod")
    },
    rightMethod() {
      this.$emit("rightMethod")
    },
    changePrimaryContent(event:any) {
      this.$emit('update:changePrimaryContent', event.target.value)
    },
    inputPrimaryContent(event:any) {
      this.$emit('update:inputPrimaryContent', event.target.value)
    },
    trimLengthOnInputField(event:any) {
      if (event.target.value.length > event.target.maxLength) {
        event.target.value = event.target.value.slice(0, event.target.maxLength)
      }
    },
    onInputBlur(event:any) {
      if(event.target.value.trim() == '') {
        event.target.value = this.primaryContent
      } 
    }
  },

})
