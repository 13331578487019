import { StatesInOffersSource } from "@/DTOs/Enums/StatesInOffersSource"
import { StateTypeEnumDTO } from "@/DTOs/StateTypeEnumDTO"
import { i18n } from "@/i18n"
import { LanguageType } from "@/i18n/languageTypes"
import { CommentModel } from "@/models/CommentModel"
import { DepartmentModel } from "@/models/DepartmentModel"
import { OfferModel } from "@/models/OfferModel"
import { PDFPageWrapperModel } from "@/models/PDFPageWrapperModel"
import { IOfferMutations, OfferMutationTypes } from "@/store/Interfaces/Offer/IOfferMutation"
import { IOfferState } from "@/store/Interfaces/Offer/IOfferState"
import axios from "axios"
import { MutationTree } from "vuex"

export const mutations: MutationTree<IOfferState> & IOfferMutations = {
  [OfferMutationTypes.SetCurrentOffer](state: IOfferState, payload: OfferModel) {
    state.CurrentOffer = payload
  },
  [OfferMutationTypes.SetCurrentComments](state: IOfferState, payload?: CommentModel[]) {
    state.CurrentComments = payload
  },
  [OfferMutationTypes.InsertNewComment](state: IOfferState, payload: CommentModel) {
    if (state.CurrentComments) {
      state.CurrentComments?.unshift(payload)
    } else {
      state.CurrentComments = [payload]
    }
  },
  [OfferMutationTypes.SetCurrentPdf](state: IOfferState, payload: Uint8Array) {
    state.PDF = payload
  },
  [OfferMutationTypes.SetPDFName](state: IOfferState, payload: string) {
    state.PDFName = payload
  },
  [OfferMutationTypes.SetPdfScale](state: IOfferState, payload: number) {
    const pdfMinScale = 0.2
    const pdfMaxScale = 5
    if (payload >= pdfMinScale && payload <= pdfMaxScale) {
      state.PdfScale = payload
    }
  },
  [OfferMutationTypes.SetCurrentPdfPage](state: IOfferState, payload: PDFPageWrapperModel) {
    if (payload.newPageNumber > 0 && payload.newPageNumber <= state.TotalNumberOfPdfPages) {
      state.CurrentPdfPageNumber = payload.newPageNumber
      state.PdfPageWasChangedManually = payload.isChangedManually
    }
  },
  [OfferMutationTypes.SetNumberOfPdfPages](state: IOfferState, payload: number) {
    state.TotalNumberOfPdfPages = payload
  },
  [OfferMutationTypes.SetPdfFullScreen](State: IOfferState, payload: boolean) {
    State.PdfIsFullScreen = payload
  },
  [OfferMutationTypes.SetSiteInitializationStatus](State: IOfferState, payload: boolean) {
    State.SiteInitializationComplete = payload
  },
  [OfferMutationTypes.SetCurrentDepartment](state: IOfferState, payload: DepartmentModel) {
    state.OfferDepartment.DepartmentName = payload.DepartmentName
    state.OfferDepartment.Phone = payload.Phone
    state.OfferDepartment.Email = payload.Email
    state.OfferDepartment.Address = payload.Address
    state.OfferDepartment.Zip = payload.Zip
    state.OfferDepartment.City = payload.City
    state.OfferDepartment.CVRNumber = payload.CVRNumber
    state.OfferDepartment.LogoAsBase64 = payload.LogoAsBase64
  },
  [OfferMutationTypes.SetCurrentOfferStatus](state: IOfferState, payload: StateTypeEnumDTO){
    state.CurrentOffer.StateId = payload
  },
  [OfferMutationTypes.SortCommentsByDateCreated](state: IOfferState) {
    state.CurrentComments = state.CurrentComments?.slice().sort((a, b) => b.DateCreated.getTime() - a.DateCreated.getTime())
  },
  [OfferMutationTypes.SetOfferAcceptedOrRejected](state: IOfferState, payload: StateTypeEnumDTO) {
    if(payload === StateTypeEnumDTO.accept) {
      state.OfferAccepted = true
    } else if(payload === StateTypeEnumDTO.reject){
      state.OfferRejected = true
    }
  },
  [OfferMutationTypes.SetCurrentSiteName](state: IOfferState, payload: string) {
    axios.defaults.headers.common['sitename'] = payload
  },
  [OfferMutationTypes.SetCurrentLanguage](state: IOfferState, payload: LanguageType) {
    state.Language = payload
    i18n.global.locale = payload
  },
  [OfferMutationTypes.SetSiteTitleToOfferNumber](state: IOfferState) {
    if(state.CurrentOffer.OfferNumber){
      document.title += ' #' + state.CurrentOffer.OfferNumber
    }
  },
  [OfferMutationTypes.SetStatesInOffersSource](state: IOfferState, payload: StatesInOffersSource) {
    state.SiteOpenedWith = payload
  }
}