import { CommitOptions, DispatchOptions, Module, Store as VuexStore } from 'vuex'
import { mutations } from "./mutations"
import { actions } from './actions'
import { IOfferState } from '@/store/Interfaces/Offer/IOfferState'
import { IOfferMutations } from '@/store/Interfaces/Offer/IOfferMutation'
import { IOfferActions } from '@/store/Interfaces/Offer/IOfferAction'
import { state } from './state'
import { getters } from './getters'

export type OfferStore<S = IOfferState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'>
& {
  commit<K extends keyof IOfferMutations, P extends Parameters<IOfferMutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<IOfferMutations[K]>;
} & {
  dispatch<K extends keyof IOfferActions, P extends Parameters<IOfferActions[K]>[1]>(
    key: K,
    payload: P,
    options?: DispatchOptions
  ): ReturnType<IOfferActions[K]>;
};

export const offer: Module<IOfferState, any> = {
  state,
  mutations,
  getters,
  actions
}