
import OfferButton from "@/components/Buttons/OfferButton.vue"
import { computed, defineComponent } from "vue"
import Comment from "./Comment.vue"
import { useStore, store } from "@/store"
import { OfferActionTypes } from "@/store/Interfaces/Offer/IOfferAction"
import { TranslationKey } from "@/i18n/translationKeys"

export default defineComponent({
  name: "comment-section",
  components: { 
    OfferButton, 
    Comment 
  },

  data() {
    return {
      newComment: "",
      hasFocus: false,
      sendComment: this.$t(TranslationKey.SendComment),
      placeholderText: this.$t(TranslationKey.CommentSectionPlaceholder),
      comments: this.$t(TranslationKey.Comments)
    }
  },

    computed: {
    Language() {
      return store.getters.GetCurrentLanguage
    }
  },

  watch: {
    Language() {
      this.sendComment = this.$t(TranslationKey.SendComment),
      this.placeholderText = this.$t(TranslationKey.CommentSectionPlaceholder),
      this.comments = this.$t(TranslationKey.Comments)
    }
  },

  setup() {
    const store = useStore()

    const totalNumberOfComments = computed(
      () => store.getters.GetTotalNumberOfComments
    )

    const allOfferComments = computed(
      () => store.getters.GetAllComments
    )

    return {
      totalNumberOfComments,
      allOfferComments
    }
  },

  methods: {
    postComment() {
      const store = useStore();
      store.dispatch(OfferActionTypes.InitializeNewComment, this.newComment)
      .catch(() =>{
      alert("An error occured posting this comment. Contact us for further information, or try again later.")
      });
      this.newComment = "";
      this.hasFocus = false;
    },

    textAreaBlur(e:any) { //eslint-disable-line
      if(e.target.value.trim() == "") {
        this.newComment = ""
        this.hasFocus = false
      }
    }
  }
})
