
import { defineComponent } from "vue"
import OfferButton from "./Buttons/OfferButton.vue"
import { StateTypeEnumDTO } from "@/DTOs/StateTypeEnumDTO"
import { OfferActionTypes } from '@/store/Interfaces/Offer/IOfferAction'
import { store, useStore } from '@/store'
import { TranslationKey } from "@/i18n/translationKeys"

export default defineComponent({
  name: "confirmation-popup",
  components: { OfferButton },
  props: {
    message: String,
    btnText: String,
    isAcceptPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      acceptChecked: false,
      confirmStatus: ConfirmStatus.none,
      ConfirmStatus,
      wishToAcceptOffer: this.$t(TranslationKey.WishToAcceptThisOffer),
      wishToRejectOffer: this.$t(TranslationKey.WishToRejectThisOffer),
      acceptOrRejectOfferConfirmed: this.$t(TranslationKey.OfferAcceptOrRejectConfirmed),
      acceptOrRejectOfferError: this.$t(TranslationKey.OfferAcceptOrRejectError),
      closeConfirmedOrErrorMessage: this.$t(TranslationKey.ClosePopup),
      cancel: this.$t(TranslationKey.Cancel),
    }
  },

  computed: {
    Language() {
      return store.getters.GetCurrentLanguage
    }
  },

  watch: {
    Language() {
      this.wishToAcceptOffer = this.$t(TranslationKey.WishToAcceptThisOffer),
      this.wishToRejectOffer = this.$t(TranslationKey.WishToRejectThisOffer),
      this.acceptOrRejectOfferConfirmed = this.$t(TranslationKey.OfferAcceptOrRejectConfirmed),
      this.acceptOrRejectOfferError = this.$t(TranslationKey.OfferAcceptOrRejectError),
      this.closeConfirmedOrErrorMessage = this.$t(TranslationKey.ClosePopup),
      this.cancel = this.$t(TranslationKey.Cancel)
    }
  },

  methods: {
    onConfirmedClick() {
      this.confirmStatus = ConfirmStatus.confirming
      if (this.isAcceptPopup) {
        this.postUpdateState(StateTypeEnumDTO.accept)
      } else {
        this.postUpdateState(StateTypeEnumDTO.reject)
      }
    },
    postUpdateState(data: StateTypeEnumDTO) {
      const store = useStore();
      store.dispatch(OfferActionTypes.UpdateOfferStatus, data)
      .then(() => {
        this.confirmStatus = ConfirmStatus.success
        if(data == StateTypeEnumDTO.accept){
          store.dispatch(OfferActionTypes.InitializePdfDownload, undefined)
        }
      })
      .catch(() => {
        this.confirmStatus = ConfirmStatus.fail
      })
    },
    closePopup() {
      if (this.confirmStatus !== ConfirmStatus.confirming) {
        this.$emit("closeMe")
        this.acceptChecked = false
        this.confirmStatus = ConfirmStatus.none
      }
    },
  },
  emits: ["closeMe"],
})

enum ConfirmStatus {
  none,
  confirming,
  success,
  fail,
}
