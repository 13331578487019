import { saveAs } from "file-saver"

export class PdfHelper {
  public static FromBase64ToUIntArray(base64String: string): Uint8Array{
    const regex = /^"|"$/g

    const decodedString = atob(base64String.replace(regex, ""))
    const byteArray = new Uint8Array(decodedString.length)

    for (let i = 0; i < decodedString.length; i++) {
      byteArray[i] = decodedString.charCodeAt(i)
    }
    return byteArray
  }

  public static DownloadPdf(byteArray: Uint8Array, name: string) : void {
    saveAs(new Blob([byteArray]), name)
  }
}