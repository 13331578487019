
import { TranslationKey } from "@/i18n/translationKeys"
import { store, useStore } from "@/store"
import { computed, defineComponent } from "vue"

export default defineComponent({
  name: "OfferExpired",

  setup() {
    const store = useStore()
    
    const DepartmentName = computed(
      () => store.getters.GetOfferDepartment.DepartmentName
    )
    const Phone = computed(
      () => store.getters.GetOfferDepartment.Phone
    )
    const Email = computed(
      () => store.getters.GetOfferDepartment.Email
    )

    return {
      DepartmentName,
      Phone,
      Email
    }
  },

   data() {
    return {
      offerExpiredHeadlineText: this.$t(TranslationKey.OfferExpiredHeadline),
      pleaseContact: this.$t(TranslationKey.PleaseContact),
      onPhone: this.$t(TranslationKey.OnPhone),
      orOn: this.$t(TranslationKey.OrOn),
      forFurtherInformation: this.$t(TranslationKey.ForFurtherInformation)
    }
  },

  computed: {
    Language() {
      return store.getters.GetCurrentLanguage
    }
  },

  watch: {
    Language() {
      this.offerExpiredHeadlineText = this.$t(TranslationKey.OfferExpiredHeadline),
      this.pleaseContact = this.$t(TranslationKey.PleaseContact),
      this.onPhone = this.$t(TranslationKey.OnPhone),
      this.orOn = this.$t(TranslationKey.OrOn),
      this.forFurtherInformation = this.$t(TranslationKey.ForFurtherInformation)
    }
  },
})
