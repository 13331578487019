import { LanguageType } from "@/i18n/languageTypes";
import { CommentModel } from "@/models/CommentModel";
import { DepartmentModel } from "@/models/DepartmentModel";
import { IOfferState } from "./IOfferState";

export enum OfferGetterTypes {
  GetOfferDepartment = 'GetOfferDepartment',
  GetPdfScale = 'GetPdfScale',
  GetCurrentPdfPage = 'GetCurrentPdfPage',
  GetPdfFullscreenValue = 'GetPdfFullscreenValue',
  GetPdfManuallyChangedValue = 'GetPdfManuallyChangedValue',
  GetSiteInitializationStatus = 'GetSiteInitializationStatus',
  GetTotalNumberOfPdfPages = 'GetTotalNumberOfPdfPages',
  GetOfferAccepted = 'GetOfferAccepted',
  GetOfferRejected = 'GetOfferRejected',
  GetTotalNumberOfComments = 'GetTotalNumberOfComments',
  GetAllComments = 'GetAllComments',
  GetPdfDocument = 'GetPdfDocument',
  GetCurrentLanguage = 'GetCurrentLanguage',
}

export interface IOfferGetters<S = IOfferState> {
  [OfferGetterTypes.GetOfferDepartment](state: IOfferState): DepartmentModel
  [OfferGetterTypes.GetPdfScale](state: S): number
  [OfferGetterTypes.GetCurrentPdfPage](state: S): number | undefined
  [OfferGetterTypes.GetPdfFullscreenValue](state: S): boolean
  [OfferGetterTypes.GetPdfManuallyChangedValue](state: S): boolean
  [OfferGetterTypes.GetSiteInitializationStatus](state: S): boolean
  [OfferGetterTypes.GetTotalNumberOfPdfPages](state: S): number
  [OfferGetterTypes.GetOfferAccepted](state: S): boolean
  [OfferGetterTypes.GetOfferRejected](state: S): boolean
  [OfferGetterTypes.GetTotalNumberOfComments](state: S): number | undefined
  [OfferGetterTypes.GetAllComments](state: S): CommentModel[] | undefined
  [OfferGetterTypes.GetPdfDocument](state: S): Uint8Array | undefined
  [OfferGetterTypes.GetCurrentLanguage](state: S): LanguageType
}