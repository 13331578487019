import { StatesInOffersSource } from "@/DTOs/Enums/StatesInOffersSource"
import { StateTypeEnumDTO } from "@/DTOs/StateTypeEnumDTO"
import { LanguageType } from "@/i18n/languageTypes"
import { CommentModel } from "@/models/CommentModel"
import { DepartmentModel } from "@/models/DepartmentModel"
import { OfferModel } from "@/models/OfferModel"
import { PDFPageWrapperModel } from "@/models/PDFPageWrapperModel"
import { IOfferState } from "./IOfferState"

export enum OfferMutationTypes {
  SetCurrentOffer = 'SetCurrentOffer',
  SetCurrentComments = 'setCurrentComments',
  InsertNewComment = 'InsertNewComment',
  SetCurrentPdf = 'setPdfData',
  SetPDFName = 'setPdfName',
  SetPdfScale = 'setScale',
  SetCurrentPdfPage = 'setPage',
  SetNumberOfPdfPages = 'setNumpages',
  SetPdfFullScreen = 'setFullScreen',
  SetSiteInitializationStatus = 'SetSiteInitializationStatus',
  SetCurrentDepartment = 'setPdfInformation',
  setCurrentPublicIdentifier = 'setCurrentPublicIdentifier',
  SetCurrentOfferStatus = 'SetCurrentOfferStatus',
  SortCommentsByDateCreated = 'SortCommentsByDateCreated',
  SetOfferAcceptedOrRejected = 'SetOfferAcceptedOrRejected',
  SetCurrentSiteName = 'SetCurrentSiteName',
  SetCurrentLanguage = 'SetCurrentLanguage',
  SetSiteTitleToOfferNumber = 'SetSiteTitleToOfferNumber',
  SetStatesInOffersSource = 'SetStatesInOffersSource',
}

export interface IOfferMutations<S = IOfferState> {
  [OfferMutationTypes.SetCurrentOffer](state: S, payload?: OfferModel): void
  [OfferMutationTypes.SetCurrentComments](state: S, payload?: CommentModel[]): void
  [OfferMutationTypes.InsertNewComment](state: S, payload: CommentModel): void
  [OfferMutationTypes.SetCurrentPdf](state: S, payload?: Uint8Array): void
  [OfferMutationTypes.SetPDFName](state: S, payload?: string): void
  [OfferMutationTypes.SetPdfScale](state: S, payload: number): void
  [OfferMutationTypes.SetCurrentPdfPage](state: S, payload: PDFPageWrapperModel): void
  [OfferMutationTypes.SetNumberOfPdfPages](state: S, payload: number): void
  [OfferMutationTypes.SetPdfFullScreen](State: S, payload: boolean): void
  [OfferMutationTypes.SetSiteInitializationStatus](State: S, payload: boolean): void
  [OfferMutationTypes.SetCurrentDepartment](State: S, payload?: DepartmentModel): void
  [OfferMutationTypes.SetCurrentOfferStatus](state: S, payload: StateTypeEnumDTO): void
  [OfferMutationTypes.SortCommentsByDateCreated](state: S): void
  [OfferMutationTypes.SetOfferAcceptedOrRejected](state: S, payload: StateTypeEnumDTO): void
  [OfferMutationTypes.SetCurrentSiteName](state: S, payload: string): void
  [OfferMutationTypes.SetCurrentLanguage](state: S, payload: LanguageType): void
  [OfferMutationTypes.SetSiteTitleToOfferNumber](state: S): void
  [OfferMutationTypes.SetStatesInOffersSource](state: S, payload: StatesInOffersSource): void
}