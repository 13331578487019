import { StatesInOffersSource } from '@/DTOs/Enums/StatesInOffersSource'
import { OfferUpdateStatusDTO } from '@/DTOs/OfferUpdateStatusDTO'
import { StateTypeEnumDTO } from '@/DTOs/StateTypeEnumDTO'

export class StateMapper {
    public static ToUpdateStatusDTOFromStateTypeEnum(state: StateTypeEnumDTO, publicIdentifier: string, openedWithID: StatesInOffersSource): OfferUpdateStatusDTO {
        return {
            publicIdentifier: publicIdentifier,
            stateID: state,
            source: openedWithID
        }
    }
}
