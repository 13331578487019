
import { computed, defineComponent } from "vue"
import { OfferActionTypes } from "@/store/Interfaces/Offer/IOfferAction"
import PdfViewer from '../components/PdfViewer/PdfViewer.vue'
import ButtonsForOfferView from "../components/ButtonsForOfferView.vue"
import CommentSection from "../components/CommentSection/CommentSection.vue"
import { useStore } from "@/store"

export default defineComponent({
  components: {
    PdfViewer,
    ButtonsForOfferView,
    CommentSection,
  },
  created() {
    this.IntializeSite();
  },
  methods: {
    IntializeSite() {
      this.$store.dispatch(OfferActionTypes.InitializeSite)
    },
  },
  setup(){
    const store = useStore()
    const siteIsInitialized = computed(
      () => store.getters.GetSiteInitializationStatus
    )
    return {
      siteIsInitialized
    }
  }
});
