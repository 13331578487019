<template>
    <div id=banner></div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "banner",
})
</script>

<style scoped>
    #banner {
        position: absolute;
        top: 0;
        left: 0;
        height: 40vh;
        width: 100%;
        z-index: -999;
        background-image: url("../assets/images/tools.png");
        background-repeat:no-repeat;
        background-size: cover;
    }

    #banner::before {
        content: "";
        display: block;
        position: relative;
        background-color: var(--primary-color-alpha);
        width: 100%;
        height: 40vh;
        z-index: -990;
    }
    
</style>
