import { createApp } from 'vue'
import { store } from './store'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import './assets/css/main.css'
import { i18n } from './i18n/index'

axios.defaults.baseURL = process.env.VUE_APP_EXTERNALAPI_URL;
axios.defaults.headers.common['isOfferPortal'] = 'true'

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .mount('#app')
