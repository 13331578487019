
import { useStore } from "@/store"
import { computed, defineComponent } from "vue"

export default defineComponent({
  name: "footer-section",

  setup() {
    const store = useStore()
    
    const DepartmentName = computed(
      () => store.getters.GetOfferDepartment.DepartmentName
    )
    const Phone = computed(
      () => store.getters.GetOfferDepartment.Phone
    )
    const Email = computed(
      () => store.getters.GetOfferDepartment.Email
    )
    const Address = computed(
      () => store.getters.GetOfferDepartment.Address
    )
    const Zip = computed(
      () => store.getters.GetOfferDepartment.Zip
    )
    const City = computed(
      () => store.getters.GetOfferDepartment.City
    )
    const CVRNumber = computed(
      () => store.getters.GetOfferDepartment.CVRNumber
    )

    return {
      DepartmentName,
      Phone,
      Email,
      Address,
      Zip,
      City,
      CVRNumber
    }
  },
  methods: {
    openGoogleMap() {
      window.open("https://www.google.com/maps/search/?api=1&query=" + this.Address + "," + this.Zip + "," + this.City, '_blank');
    }
  }
})
