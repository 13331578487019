
import { computed, defineComponent } from "vue"
import { LanguageType } from "@/i18n/languageTypes"
import { useStore } from "@/store"
import { OfferMutationTypes } from "@/store/Interfaces/Offer/IOfferMutation"
import { allLanguages } from "@/i18n"

export default defineComponent({
  name: "offer-navigation-bar",

  setup() {
    const store = useStore()
    const currentLanguage = computed(
      () => store.getters.GetCurrentLanguage
    )

    return {
      currentLanguage
    }
  },

  data() {
    return {
      store: useStore(),
      languageType: LanguageType,
      allLanguages: allLanguages
    }
  },

  methods: {
    changeLanguage(targetLanguage: any): void {
      this.store.commit(OfferMutationTypes.SetCurrentLanguage, targetLanguage)
    }
  }
})
