import { OfferReportDTO } from "@/DTOs/OfferReportDTO"
import { ReportModel } from "@/models/ReportModel"

export class ReportMapper {
  public static ToModelFromDTO(dto: OfferReportDTO): ReportModel {
    return {
      FileName: dto.fileName,
      FileData: dto.fileData
    }
  }
}